<div class="input-time" [class.input-time--required]="required" [formGroup]="group">
  <label
    class="input-time__label"
    [for]="forId"
  >
    {{ label }}
    <fa-icon *ngIf="required"
      class="input-time__required-icon"
      [icon]="faAsterisk"
    ></fa-icon>
  </label>
  <input
    class="input-time__input"
    type="time"
    [name]="name"
    [id]="forId"
    [attr.required]="required"
    [formControlName]="controlName"
    step="600"
  />
</div>
