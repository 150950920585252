<app-hero></app-hero>

<div class="row">
  <div class="home-songlist col-lg mb-5">
    <app-card fullHeight="true">
      <h2 class="sb-heading-2">Songlist
        <span style="font-size: 1.5rem">(<a [routerLink]="['/songs']">view in full page</a>)</span>
      </h2>
      <p>Are you currently watching Steve perform, live? Request a song from his list. He'll be happy to play it, if he hasn't already.</p>
      <app-spinner *ngIf="isFetching; else songs"></app-spinner>
      <ng-template #songs>
        <app-alpha-list
          class="sb-home__alphalist"
          [showLetters]="letters"
          [parentSelector]="'app-song-list'"
        ></app-alpha-list>
        <app-song-list class="sb-home__songlist" [covers]="covers"></app-song-list>
      </ng-template>
    </app-card>
  </div>
  <div class="home-performancelist col-lg mb-5">
    <div class="home-performancelist__wrap">
      <h2 class="sb-heading-2 performance-heading">Performances</h2>
      <app-performances-list></app-performances-list>
    </div>
  </div>
</div>
