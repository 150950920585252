<button
  *ngIf="showTop"
  class="sb-alphalist-link sb-link sb-link--decorate-none"
  (click)="scrollToSection('top')"
>#</button>
<button
  *ngFor="let letter of alphabet"
  class="sb-alphalist-link sb-link sb-link--decorate-none"
  [class.sb-alphalist-link--disabled]="!showLetters.includes(letter)"
  (click)="scrollToSection(showLetters.includes(letter) ? letter : '')"
>{{ letter }}</button>
