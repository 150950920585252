<div class="sb-header-container">
  <h1 class="sb-header-container__title sb-secondary-font">
    <svg class="sb-header-container__title-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1680 2031">
      <defs>
        <style>
          .cls-1, .cls-2, .cls-3 {
            fill: none;
            stroke: #7e57c2;
          }

          .cls-1 {
            stroke-width: 40px;
          }

          .cls-1, .cls-2 {
            fill-rule: evenodd;
          }

          .cls-2, .cls-3 {
            stroke-width: 20px;
          }
        </style>
      </defs>
      <path id="Outer" class="cls-1" d="M835.108,1990.17C630.394,1990.17,35.846,1021.98,40,600S429.453,40,830,40c399.45,0,810,134.362,810,560S1039.82,1990.17,835.108,1990.17Z"/>
      <path id="Inner" class="cls-2" d="M830.566,1899.75c-185.527,0-724.354-888.49-720.589-1275.724s352.953-513.9,715.96-513.9c362.013,0,734.083,123.3,734.083,513.9S1016.09,1899.75,830.566,1899.75Z"/>
      <circle class="cls-3" cx="835" cy="765" r="498.75"/>
    </svg>
    <a class="sb-header-container__title-link sb-link--decorate-none" [routerLink]="['']">Steve Black</a>
  </h1>
  <nav class="sb-header-container__nav" aria-label="Site Navigation">
    <!-- <ul class="sb-header-main-nav">
      <li class="sb-header-main-nav__item"><a class="sb-link--decorate-none sb-header-main-nav__link" [routerLink]="['performances']">Performances</a></li>
      <li class="sb-header-main-nav__item"><a class="sb-link--decorate-none sb-header-main-nav__link" [routerLink]="['contact']">Contact</a></li>
    </ul> -->
  </nav>
  <div class="sb-header-container__action">
    <nav role="list" aria-label="Social Media Navigation">
      <a
        role="listitem"
        class="sb-header-media sb-link--decorate-none"
        href="https://www.facebook.com/Steve-Black-121279740594" title="Facebook">
        <fa-icon [icon]="faFacebook"></fa-icon>
        <span class="sr-only">Facebook</span>
      </a>
      <a
        role="listitem"
        class="sb-header-media sb-link--decorate-none"
        href="https://www.youtube.com/c/SteveBlackWeb/videos" title="YouTube">
        <fa-icon [icon]="faYoutube"></fa-icon>
        <span class="sr-only">YouTube</span>
      </a>
      <a
        role="listitem"
        class="sb-header-media sb-link--decorate-none"
        href="https://www.venmo.com/steveblackmusic" title="Donate via Venmo">
        <svg class="venmo-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 162 162" style="enable-background:new 0 0 162 162;" xml:space="preserve">
          <path class="st0" d="M136.8,14.9c4.8,8,7,16.2,7,26.6c0,33.2-28.3,76.2-51.3,106.5H40L19,22.1l46-4.4l11.1,89.6
            c10.4-16.9,23.2-43.6,23.2-61.7c0-9.9-1.7-16.7-4.4-22.3L136.8,14.9z"/>
        </svg>
        <span class="sr-only">Donate via Venmo</span>
      </a>
      <a
        role="listitem"
        class="sb-header-media sb-link--decorate-none"
        href="https://paypal.me/steveblackmusic" title="Donate via Paypal">
        <fa-icon [icon]="faPaypal"></fa-icon>
        <span class="sr-only">Donate via Paypal</span>
      </a>
    </nav>
    <button
      class="sb-button--icon"
      [title]="themeLabel"
      (click)="themeService.toggleTheme()">
      <fa-icon [icon]="faAdjust"></fa-icon>
    </button>
  </div>
</div>
