<div class="row flex-wrap">
  <div class="col-sm-3">
    <form class="sb-add-song" [formGroup]="songForm" (ngSubmit)="onSubmit()">
      <h2 class="sb-add-song__title sb-heading-1">Add Song</h2>
      <app-input-text
        placeholder="Artist"
        label="Artist Name"
        name="artist"
        required="true"
        [list]="artists"
        controlName="artist"
        [group]="songForm"
      ></app-input-text>
      <app-input-text
        placeholder="Song Title"
        label="Song"
        name="song"
        required="true"
        controlName="song"
        [group]="songForm"
      ></app-input-text>
      <div class="mt-3">
        <button type="submit" [disabled]="!songForm.valid">Add Song</button>
        <button class="ml-2" type="reset">Clear</button>
      </div>
    </form>
  </div>
  <div class="col-sm-4">
    <form class="sb-add-song" [formGroup]="performanceForm" (ngSubmit)="submitPerformance()">
      <h2 class="sb-add-song__title sb-heading-1">Add Performance</h2>
      <div *ngIf="!showVenueForm">
        <app-input-text
          placeholder="Venue"
          label="Venue"
          name="venue"
          required="true"
          [list]="venuesList"
          controlName="venue"
          [group]="performanceForm"
        ></app-input-text>
        <div class="mt-3">
          <button type="button" (click)="toggleVenueForm()">Add Venue</button>
        </div>
      </div>
      <div *ngIf="showVenueForm">
        <fieldset class="">
          <legend>Venue</legend>
          <div class="row">
            <div class="col-3">
              <app-input-text
                placeholder="Name"
                label="Name"
                name="name"
                required="true"
                controlName="name"
                [group]="performanceForm"
              ></app-input-text>
            </div>
            <div class="col-3">
              <app-input-text
                placeholder="Phone (123)456-7890"
                label="Phone"
                name="phone"
                controlName="phone"
                [group]="performanceForm"
              ></app-input-text>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <app-input-text
                placeholder="Email"
                label="Email"
                name="email"
                controlName="email"
                [group]="performanceForm"
              ></app-input-text>
            </div>
            <div class="col-3">
              <app-input-text
                placeholder="http://"
                label="Website"
                name="website"
                controlName="website"
                [group]="performanceForm"
              ></app-input-text>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <app-input-text
                placeholder="123 Smith Ave."
                label="Address Line 1"
                name="addressLineOne"
                controlName="addressLineOne"
                [group]="performanceForm"
              ></app-input-text>
            </div>
            <div class="col-3">
              <app-input-text
                placeholder="Apt. 5"
                label="Address Line 2"
                name="addressLineTwo"
                controlName="addressLineTwo"
                [group]="performanceForm"
              ></app-input-text>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <app-input-text
                label="City"
                name="city"
                controlName="city"
                [group]="performanceForm"
              ></app-input-text>
            </div>
            <div class="col-3">
              <app-input-text
                label="State"
                name="state"
                [list]="['NY', 'CT', 'PA', 'NJ', 'MA', 'NH']"
                controlName="state"
                [group]="performanceForm"
              ></app-input-text>
            </div>
            <div class="col-3">
              <app-input-text
                label="Zip"
                name="zip"
                controlName="zip"
                [group]="performanceForm"
              ></app-input-text>
            </div>
          </div>
          <div class="mt-3">
            <button type="button" (click)="toggleVenueForm()">Cancel Add Venue</button>
          </div>
        </fieldset>
      </div>
      <app-input-text
        label="Revenue"
        name="revenue"
        controlName="revenue"
        [group]="performanceForm"
      ></app-input-text>
      <app-input-date
        label="Date"
        name="date"
        required="true"
        controlName="date"
        [group]="performanceForm"
      ></app-input-date>
      <div class="row">
        <div class="col-sm">
          <app-input-time
            label="Time Start"
            name="timeStart"
            required="true"
            controlName="timeStart"
            [group]="performanceForm"
          ></app-input-time>
        </div>
        <div class="col-sm">
          <app-input-time
            label="Time End"
            name="timeEnd"
            required="true"
            controlName="timeEnd"
            [group]="performanceForm"
          ></app-input-time>
        </div>
      </div>
      <app-input-text
        label="Cover Charge?"
        name="coverCharge"
        controlName="coverCharge"
        [group]="performanceForm"
      ></app-input-text>
      <app-input-textarea
        label="Notes"
        name="notes"
        controlName="notes"
        [group]="performanceForm"
      ></app-input-textarea>
      <div class="mt-3">
        <button type="submit" [disabled]="!performanceForm.valid">Add Performance</button>
        <button class="ml-2" type="reset">Clear</button>
      </div>
    </form>
  </div>
  <div class="col-sm mt-5 sb-background-color">
    <div *ngFor="let performance of performances.reverse()">
      {{ performance.date }} &nbsp; {{ performance.venueDetails.name }} &nbsp; {{ parseTime(performance.timeStart) }} - {{ parseTime(performance.timeEnd) }}
    </div>
  </div>
</div>

<app-alpha-list
  class="sb-add-song-alpha-list"
  [showTop]="true"
  [parentSelector]="'.sb-add-song__songlist'"
></app-alpha-list>
<div class="sb-add-song__songlist">
  <app-spinner class="sb-add-song__spinner" *ngIf="isFetching"></app-spinner>
  <app-song-list
    [class.sb-add-song__songlist--hide]="isFetching"
    [covers]="coversService.covers"
    showCount="true"
    interactive="true"
    (artist)="deleteArtist($event)"
    (song)="deleteSong($event)"
  ></app-song-list>
</div>
